/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-shadow */
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { INotes } from 'src/app/features/models/inotes.modal';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { tableColumns } from 'src/app/shared/constants/table-column.constants';
import { AdminHeader } from 'src/app/shared/models/admin-header.model';
import { AllAdminService } from 'src/app/shared/services/all-admin.service';
import { UtcToLocalPipe } from 'src/app/shared/pipes/utc-to-local.pipe';
import { SharedService } from 'src/app/features/service/shared.service';
import { WorksheetService } from '../../services/worksheet.service';
import { AssignToLabtechComponent } from '../../../../shared/components/assign-to-labtech/assign-to-labtech.component';
import { PrecastWorksheet } from '../../models/precast-worksheet.modal';
import { EditPrecastWorksheetComponent } from '../edit-precast-worksheet/edit-precast-worksheet.component';

@Component({
  selector: 'app-precast-table',
  templateUrl: './precast-table.component.html',
  styleUrls: ['./precast-table.component.scss'],
  providers: [UtcToLocalPipe],
})
export class PrecastTableComponent implements OnInit {
  dataSource: MatTableDataSource<PrecastWorksheet>;
  @Input() projectId: string;
  @Output() assigned = new EventEmitter<boolean>();
  @Output() rowDeletionSuccess = new EventEmitter<void>();
  public worksheets: PrecastWorksheet[];
  private readonly destroyed$ = new Subject<boolean>();
  public note: INotes | undefined;
  public isGray = true;
  public displayedColumns: string[] = tableColumns.worksheetColumns;
  headerData: AdminHeader[] = [];
  rowData: PrecastWorksheet[] = [];
  headerKeyToRowKeyMapping: { [key: string]: string } = {
  };

  @Output() allResPrecastWorksheet = new EventEmitter<any>();
  @Output() headerPrecastWorksheet = new EventEmitter<any>();
  deleteBtn = false;
  constructor(
    public userService: UserService,
    public dialogService: DialogService,
    public dialog: MatDialog,
    private worksheetService: WorksheetService,
    private adminService: AllAdminService,
    private sharedService: SharedService,
    private utcToLocalPipe: UtcToLocalPipe,
  ) {}

  ngOnInit() {
    this.fetchWorksheet(this.projectId);
    this.getColumnHeader();
  }

  getColumnHeader() {
    this.adminService.getAdminPrecast().subscribe(
      (response) => {
        this.headerData = response;
        this.headerData.sort((a, b) => a.sortOrder - b.sortOrder);
        this.displayedColumns = this.headerData.map((header) => header.key);
        this.dataSource = new MatTableDataSource(this.worksheets);
        this.headerPrecastWorksheet.emit(this.headerData);
        this.getColumnkeyMatch();
      },
      (error) => {
        console.error('Error fetching tabs data:', error);
      },
    );
  }

  // Match header key value with row key value
  getColumnkeyMatch() {
    this.headerKeyToRowKeyMapping = {
    };
    this.headerData.forEach((header) => {
      this.headerKeyToRowKeyMapping[header.key] = header.key;
    });
  }

  getTooltipText(columnName: string): string {
    const header = this.headerData.find((header) => header.key === columnName);
    return header ? header.tooltipText : '';
  }

  // Displays the header value based on sorted order
  getDisplayName(columnName: string): string {
    const header = this.headerData.find((header) => header.key === columnName);
    if (header) {
      return header.units ? `${header.displayName} <br>${header.units}` : header.displayName;
    }
    return '';
  }

  // Displays the column row value
  getRowDataByKey(row: any, key: string): string {
    const rowKey = this.headerKeyToRowKeyMapping[key];
    const value = row[rowKey];
    // Custom date formatting for 'castDate',columns
    if (key === 'castDate') {
      if (value === null) {
        return '';
      }
      return this.utcToLocalPipe.transform(value, 'date');
    }

    if (key === 'assignedTo') {
      if (value && value.firstName && value.lastName) {
        return `${value.firstName} ${value.lastName}`;
      }
      return '';
    }
    return value;
  }

  openAssignToDialog(worksheetId: string): void {
    this.dialog
      .open(AssignToLabtechComponent, {
        data: {
          worksheetId,
          type: 'precast',
        },
        disableClose: true,
        width: '40%',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((assigned) => {
        if (assigned) {
          this.fetchWorksheet(this.projectId);
          this.assigned.emit(true);
        }
      });
  }

  fetchWorksheet(projectId: string): void {
    this.worksheetService
      ?.fetchWorksheetbyId(projectId)
      ?.pipe(takeUntil(this.destroyed$))
      ?.subscribe({
        next: (worksheets) => {
          let initial = 0;
          this.worksheets = worksheets;
          const fetchWorksheet = JSON.parse(JSON.stringify(worksheets));
          const countGroupOrder = this.worksheets.filter((worksheet) => worksheet.groupOrder === 0 || worksheet.groupOrder === null);
          this.worksheets.forEach((worksheet) => {
            if (!worksheet.groupOrder) {
              initial += 1;
            }
            worksheet.sequence = initial;
          });
          this.dataSource = new MatTableDataSource<PrecastWorksheet>(this.worksheets);
          fetchWorksheet.forEach((worksheet:PrecastWorksheet) => {
            if (worksheet.truckNumber && worksheet.ticketNumber) {
              worksheet.sampleId = `${worksheet.sampleId}, Truck# ${worksheet.truckNumber}, Ticket# ${worksheet.ticketNumber}`;
            } else if (worksheet.ticketNumber) {
              worksheet.sampleId = `${worksheet.sampleId}, Ticket# ${worksheet.ticketNumber}`;
            } else if (worksheet.truckNumber) {
              worksheet.sampleId = `${worksheet.sampleId}, Truck# ${worksheet.truckNumber}`;
            } else {
              worksheet.sampleId = `${worksheet.sampleId}`;
            }
          });

          fetchWorksheet.forEach((worksheet:any) => {
            if (worksheet.assignedTo) {
              const updatedByFirstName = worksheet.assignedTo?.firstName;
              const updatedByLastName = worksheet.assignedTo?.lastName;
              worksheet.assignedTo = `${updatedByFirstName} ${updatedByLastName}`;
            }
          });

          /**
         * Formats the castDate of each worksheet in fetchWorksheet.
         *  Array of worksheet objects, each containing a castDate in ISO format.
         * This function extracts the date part (YYYY-MM-DD) from the castDate
         * and updates it in the worksheet.
         */
          const formatDate = (dateString:any) => {
            if (!dateString) return '';
            // Parse the date from the input string
            const parsedDate = new Date(dateString);
            const localDateString = this.sharedService.transform(parsedDate.toISOString());
            // Remove commas and return the formatted date (YYYY-MM-DD)
            return localDateString.replace(/,/g, '').split(' ')[0];
          };

          fetchWorksheet.forEach((worksheet: any) => {
            if (worksheet.castDate) {
              const formattedCastDate = formatDate(worksheet.castDate);
              worksheet.castDate = `${formattedCastDate} `;
            }
          });

          // this emit logic only for excel preview and download excel file
          this.allResPrecastWorksheet.emit(fetchWorksheet);
          if (countGroupOrder.length > 1) {
            this.deleteBtn = false;
          } else {
            this.deleteBtn = true;
          }
        },
      });
  }

  editWorksheet(element: PrecastWorksheet): void {
    const worksheets = this.worksheets.filter((worksheet) => !worksheet.groupOrder && worksheet.Tech);
    this.dialog
      .open(EditPrecastWorksheetComponent, {
        panelClass: 'worksheet-dialog',
        data: {
          element,
          worksheets,
          mode: 'UPDATE',
        },
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((mode: string) => {
        if (mode !== 'close') {
          this.fetchWorksheet(this.projectId);
        }
      });
  }

  deleteWorksheet(element: string): void {
    if (this.worksheets.length > 1) {
      this.dialog
        .open(ConfirmationDialogComponent, {
          panelClass: 'worksheet-dialog',
          data: {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
          },
          disableClose: true,
        })
        .afterClosed()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response?: string) => {
          if (response === 'YES') {
            this.worksheetService
              .deleteWorksheet(element)
              .pipe(takeUntil(this.destroyed$))
              .subscribe({
                next: () => {
                  this.fetchWorksheet(this.projectId);
                  this.rowDeletionSuccess.emit();
                  this.dialogService.open(messagesConfig.worksheetDeleteMessage);
                },
                error: () => {},
              });
          }
        });
    } else {
      this.dialogService.open(messagesConfig.worksheetDeleteValidation);
    }
  }
}
