/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import htmlToPdfmake from 'html-to-pdfmake';
import { IADDRESS } from 'src/app/shared/models/i-address.modal';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { PdfStyles } from '../constants/pdf-styles-constants';
import { PdfNativeTableColumns, PdfPrecastTableColumns } from '../constants/pdf-table-columns-connstants';
import { PdfNativeMergedHeader,
  PdfNativeTableHeader,
  PdfPrecastMergedHeader,
  PdfPrecastTableHeader } from '../constants/pdf-table-header-constants';

@Injectable({
  providedIn: 'root',
})
export class PdfReportUtils {
  previewData: REPORTPREVIEW;
  soilType: string;
  title = '';
  signature: ISIGNATURE;
  logo: string;
  address: IADDRESS;
  figureImages: any[] = [];
  imageCount = 0;
  forPdfTableDta: any[] = [];
  private destroyed$ = new Subject<boolean>();
  constructor(private datePipe: DatePipe) {}

  public commonForPdfData(
    previewValue: REPORTPREVIEW,
    soilType: string,
    title: string,
    sign: ISIGNATURE,
    logo: string,
    address: IADDRESS,
    figureImages: any,
    imageCount: number,
    forPdfTableDta: any,
  ) {
    this.previewData = previewValue;
    this.soilType = soilType;
    this.title = title;
    this.signature = sign;
    this.logo = logo;
    this.address = address;
    this.figureImages = figureImages;
    this.imageCount = imageCount;
    this.forPdfTableDta = forPdfTableDta;
  }

  public exportPDF(): void {
    const figureImages = [];
    for (let i = 0; i < this.imageCount; i++) {
      const canvas = document.getElementById(`canvas${i}`) as HTMLCanvasElement;
      figureImages.push({
        image: canvas.toDataURL('image/png', 1.0),
      });
    }
    this.figureImages = [...figureImages];

    let PdfTableColumns;
    if (this.soilType === SoilType.Native) {
      PdfTableColumns = PdfNativeTableColumns;
    } else if (this.soilType === SoilType.Precast) {
      PdfTableColumns = PdfPrecastTableColumns;
    }

    const contentArray: any[] = [
      ...this.getLogoAndAdress(),
      {
        text: `${this.datePipe.transform(this.previewData.reportDate, 'mediumDate')}`,
        style: 'datebold',
        margin: [30, 0, 0, 0],
      },
      {
        text: `${this.previewData.companyName}`,
        style: 'bold',
        margin: [30, 0, 0, 0],
      },
      {
        text: `${this.previewData.streetAddress}`,
        margin: [30, 0, 0, 0],
      },
      {
        text: `${this.previewData.cityState}`,
        margin: [30, 0, 0, 0],
      },
      {
        text: `Attn: ${this.previewData.contactName}`,
        style: 'datebold',
        decoration: 'underline',
        margin: [30, 0, 0, 0],
      },
      {
        text: `${this.previewData.header}`,
        style: 'centerTitle',
        margin: [30, 10, 0, 0],
      },
      {
        text: `${this.previewData.subHeader}`,
        style: 'centerTitle2',
        decoration: 'underline',
        margin: [30, 0, 0, 10],
      },
      ...this.getMainBody(),
      this.table([...this.forPdfTableDta], PdfTableColumns),
      this.getCommentsAdded(),
    ];
    // Conditionally add the Geotherm Comments section
    if (this.previewData.geothermComment) {
      contentArray.push(
        {
          text: 'Geotherm Comments:',
          style: 'geothermtitle',
          margin: [30, 10, 30, 10],
        },
        this.getGeothermComment(),
      );
    }
    // Conditionally add the Recommendation section
    if (this.previewData.recommendations) {
      contentArray.push(
        {
          text: 'Recommendations:',
          style: 'geothermtitle',
          margin: [30, 10, 30, 10],
        },
        this.getRecommendationComment(),
      );
    }

    contentArray.push(
      this.getSignature(),
      ...this.putImagesInPdf(),
    );

    const docDefinition :TDocumentDefinitions = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      pageOrientation: 'portrait',
      footer: () => [
        {
          text: 'COOL SOLUTIONS FOR UNDERGROUND POWER CABLES',
          style: 'footer',
        },
        {
          text: 'THERMAL SURVEYS, CORRECTIVE BACKFILLS & INSTRUMENTATION',
          style: 'footer',
        },
        {
          text: 'Serving the electric power industry since 1978',
          style: 'footer1',
        },
      ],
      content: contentArray,
      styles: PdfStyles as any,
    };

    pdfMake.createPdf(docDefinition).download(this.title);
  }

  getLogoAndAdress() {
    const lAndA = [];
    lAndA.push({
      columns: [
        {
          image: this.logo,
          width: 200,
          margin: [30, 0, 0, 0],
        },
        {
          stack: [
            {
              text: `${this.address.street1}, ${this.address.street2}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
            },
            {
              text: `${this.address.city}, ${this.address.state}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
            },
            {
              text: `Tel: ${this.address.ph}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
            },
            {
              text: `${this.address.email}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
              style: 'blueLink',
            },
            {
              text: `${this.address.weburl}`,
              style: 'blueLink',
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
              link: `${this.address.weburl}`,
            },
          ],
        },
      ],
    });
    return lAndA;
  }

  getMainBody() {
    if (this.soilType === SoilType.Precast) {
      return this.getMainBodyPrecast();
    }
    return this.getMainBodyNativeSoil();
  }

  getMainBodyNativeSoil() {
    const mainBody = [];
    mainBody.push({
      text: `${this.previewData.mainBodyIntro}`,
      style: 'mainBody',
    });
    mainBody.push(
      {
        text: [
          {
            text: 'Thermal Resistivity Tests: ',
            bold: true,
            decoration: 'underline',
            style: 'mainBodyTitle',
          },
          {
            text: `${this.previewData.mainBody}`,
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 10],
      },
      {
        text: 'Sample ID, Description, Thermal Resistivity, Moisture Content and Density',
        style: 'sampleHead',
      },
    );
    return mainBody;
  }

  getMainBodyPrecast() {
    const mainBody = [];
    mainBody.push(
      {
        text: [
          {
            text: `${this.previewData.mainBody}`,
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 10],
      },
      {
        text: 'Test Results: (Average of Cylinders)',
        style: 'sampleHead',
      },
    );
    return mainBody;
  }

  getCommentsAdded() {
    if (this.soilType === SoilType.Precast) {
      return this.getCommentAddedPrecast();
    }
    return this.getCommentAddedNative();
  }

  getCommentAddedPrecast() {
    const comments = [
      {
        text: [
          {
            text: 'Client Comments:',
            bold: true,
            decoration: 'underline',
            style: 'mainBodyTitle',
          },
        ],
        margin: [30, 10, 30, 5],
      },
    ];
    if (this.previewData.commentPart1) {
      comments.push({
        text: [
          {
            text: `Concrete: ${this.previewData.commentPart1}`,
            bold: false,
            decoration: '',
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    if (this.previewData.commentPart2) {
      comments.push({
        text: [
          {
            text: `FTB: ${this.previewData.commentPart2}`,
            bold: false,
            decoration: '',
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    if (this.previewData.commentPart3) {
      comments.push({
        text: [
          {
            text: `Grout/Slurry: ${this.previewData.commentPart3}`,
            bold: false,
            decoration: '',
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    if (this.previewData.commentPart4) {
      comments.push({
        text: [
          {
            text: `Other: ${this.previewData.commentPart4}`,
            bold: false,
            decoration: '',
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    comments.push({
      text: [
        {
          text: `${this.previewData.commentPart5}`,
          bold: false,
          decoration: '',
          style: 'mainBody',
        },
      ],
      margin: [30, 0, 30, 0],
    });
    return comments;
  }

  getCommentAddedNative() {
    const comments = [
      {
        text: [
          {
            text: 'Client Comments: ',
            bold: true,
            decoration: 'underline',
            style: 'mainBodyTitle',
          },
        ],
        margin: [30, 10, 30, 5],
      },
    ];
    if (this.previewData.commentPart1) {
      comments.push({
        text: [
          {
            text: `${this.previewData.commentPart1}`,

            style: 'mainBody',
            bold: false,
            decoration: '',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    comments.push({
      text: [
        {
          text: `${this.previewData.commentPart2}`,
          style: 'mainBody',
          bold: false,
          decoration: '',
        },
      ],
      margin: [30, 0, 30, 0],
    });
    return comments;
  }

  getGeothermComment() {
    const geothermCommentContent = this.previewData.geothermComment
      ? htmlToPdfmake(this.previewData.geothermComment)
      : [];
    return [
      {
        stack: geothermCommentContent,
        margin: [30, -5, 30, -5],
        style: 'mainBody',
      },
    ];
  }

  getRecommendationComment() {
    const recommendationsContent = this.previewData.recommendations
      ? htmlToPdfmake(this.previewData.recommendations)
      : [];
    return [
      {
        stack: recommendationsContent,
        margin: [30, -5, 30, -5],
        style: 'geocomment',
      },
    ];
  }

  getSignature() {
    const signature = [];
    signature.push({
      text: [
        {
          text: 'Geotherm USA, LLC',
          style: 'mainBody',
        },
      ],
      margin: [30, 5, 30, 0],
    });
    signature.push({
      image: `data:image/png;base64,${this.signature.signatureImage}`,
      width: 80,
      margin: [30, 5, 30, 0],
    });

    signature.push({
      text: [
        {
          text: `${this.signature.label}`,
          style: 'mainBody',
        },
      ],
      margin: [30, 5, 30, 0],
      pageBreak: 'after',
    });
    return signature;
  }

  putImagesInPdf() {
    const imageArray: any[] = [];
    let pageBreaks = 'after';
    this.figureImages.forEach((images, i) => {
      if (i === this.figureImages.length - 1) {
        pageBreaks = '';
      }
      imageArray.push(
        {
          image: this.logo,
          width: 150,
          margin: [30, 0, 0, 20],
        },
        {
          text: `THERMAL DRYOUT CURVE - FIGURE ${i + 1}`,
          style: 'imageTitle',
        },
        {
          image: images.image,
          width: 480,
          height: 480,
          style: 'imageStyle',
          alignment: 'center',
          margin: [30, 20, 0, 20],
          pageBreak: pageBreaks,
        },
      );
    });
    return imageArray;
  }

  table(data: any, columns: any) {
    return {
      table: {
        headerRows: 2,
        body: this.buildTableBody(data, columns),
      },
      margin: [30, 0, 30, 0],
    };
  }

  buildTableBody(data: any, columns: any) {
    const body: any[] = [];
    this.setTableHeader(body, this.soilType);
    data.forEach((row: any) => {
      const dataRow: any = [];
      columns.forEach((column: any) => {
        dataRow.push({
          text: row[column].toString(),
          style: 'tableDataStyle',
          alignment: 'center',
        });
      });

      body.push(dataRow);
    });

    return body;
  }

  setTableHeader(body: any, soilType: string): void {
    if (soilType === SoilType.Native) {
      body.push(PdfNativeTableHeader);
      body.push(PdfNativeMergedHeader);
    } else if (soilType === SoilType.Precast) {
      body.push(PdfPrecastTableHeader);
      body.push(PdfPrecastMergedHeader);
    }
  }

  ngOnDestroy(): void {
    // avoid memory leakage please don't remove the line
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
