<div class="gcontainer report-preview-wrapper" *ngIf="previewData">
  <div class="col-md-11">
    <nav aria-label="breadcrumb" class="mt-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active breadcrumb cursor" aria-current="page">
          <a class="c-primary" [routerLink]="'/dashboard'" tabindex="0"> Dashboard </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          <span *ngIf="projectName"> {{ projectName }} - </span>
          Report -
          <span *ngIf="soilType === 'precast'">Pre Cast</span>
          <span *ngIf="soilType === 'nativesoil'">Native Soil</span>
        </li>
      </ol>
    </nav>
  </div>
  <div class="report-header">
    <div class="head">
      <h1>Thermal Resistivity Report</h1>
      <span>{{ title }}</span>
    </div>
    <div class="banner-wrapper">
      <div class="r-l">
        <span class="st c-primary">{{ statusLabel }}</span>
        <span class="an">Assigned To: {{ reportOwner }}</span>
      </div>
      <div class="r-r">
        <app-customer-attachments
          [projectId]="projectId"
          [backgroundClass]="'yellow'"
          [showSuccess]="false"
          style="vertical-align: middle; margin-right: 16px"></app-customer-attachments>
        <button class="c-primary" (click)="openViewSubmissionForm()">
          <img src="assets/icons/File.svg" alt="" />View Submission Form
        </button>
        <button class="c-primary" (click)="openWorksheet()">
          <img src="assets/icons/File.svg" alt="" />View Worksheet
        </button>
        <button class="c-primary" (click)="openAssignToDialog()">
          <img src="assets/icons/people.svg" alt="" />Assign
        </button>
        <button class="c-primary" (click)="openSetStatusDialog()">
          <img src="assets/icons/Done.svg" alt="" />Set Status
        </button>
        <button class="c-primary" *ngIf="false"><img src="assets/icons/create.svg" alt="" />Create Revision</button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div
      class="r-tab-wrapper">
      <mat-tab-group
       *ngIf="showTabs"
        #tabGroup
        animationDuration="0"
        [(selectedIndex)]="selectedIndex"
        (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="PREVIEW"  *ngIf="!geothermField">
          <div class="preview-wrapper">
            <span>Date Modified: {{ previewData.updatedAt | utcToLocal | date : 'short' }}</span>
            <span>Figures Completed - {{ figCompletedCount() }} out of {{ figureCount }}</span>
            <app-preview
            id="app-preview-doc"
              *ngIf="projectId"
              [projectId]="projectId"
              [PONumber]="PONumber"
              (getImageCount)="getImageCount($event)"
              [soilType]="soilType"
              [signature]="signature"
              [address]="address"
              [preview]="previewData">
            </app-preview>
          </div>
          <div class="print-button-wrapper" *ngIf="userService.roleCheck(['Reportwriter', 'Admin'])">
            <button mat-raised-button color="primary" (click)="generateWord()" style="margin-right: 15px;">Download Report(docx)</button>
            <button mat-raised-button color="primary" (click)="generatePDF()">Download Report(pdf)</button>
          </div>
        </mat-tab>
        <mat-tab label="EDIT">
          <app-edit-report
            (savedNotify)="getSavedNotify($event)"
            (previewClicked)="changeTab(tabGroup, $event)"
            [hasBillingInfo]="hasBillingInfo"
            [projectId]="projectId"
            [exportShow]="geothermField"
            [soilType]="soilType"
            [sigArray]="sigArray"
            [previewData]="previewData"
            [figureCount]="figureCount"
            [figureCompletedCount]="figureCompletedCount"></app-edit-report>
        </mat-tab>
        <mat-tab label="RECOMMENDATIONS" *ngIf="!geothermField">
          <app-recommendation
          (savedNotify)="getSavedNotify($event)"
          [reportId]="reportId">
          </app-recommendation>
        </mat-tab>
      </mat-tab-group>
      <div 
        class="print-button-wrapper"
        *ngIf="userService.roleCheck(['Admin']) && geothermField">
        <button mat-raised-button color="primary" (click)="exportcsv()">DOWNLOAD DATA</button>
      </div>
    </div>
  </div>
</div>
