/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-case */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { forkJoin, Subject } from 'rxjs';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { IADDRESS } from 'src/app/shared/models/i-address.modal';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { FormService } from 'src/app/shared/services/form.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ReportService } from 'src/app/features/report/services/report.service';
import { ADMINGENERAL } from 'src/app/shared/models/i-admin-general.modal';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';
import { AssignToLabtechComponent } from 'src/app/shared/components/assign-to-labtech/assign-to-labtech.component';
import { MatDialog } from '@angular/material/dialog';
import { SetStatusComponent } from 'src/app/shared/components/set-status/set-status.component';
import { IDropdown } from 'src/app/shared/models/i-dropdown-type.modal';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { AllAdminService } from 'src/app/shared/services/all-admin.service';
import { SharedService } from 'src/app/features/service/shared.service';
import { WorksheetResponse } from 'src/app/features/worksheet/models/worksheet-response.modal';
import { INotes } from 'src/app/features/models/inotes.modal';
import { ExportTableService } from 'src/app/features/service/export-table.service';
import { AzureAdB2C } from 'src/environments/environment';
import { ISetStatus } from 'src/app/features/models/istatus-response.modal';
import { PdfReportUtils } from '../../services/pdf-report.utils';
import { WordReportUtils } from '../../services/word-report.utils';
import { PdfStyles } from '../../constants/pdf-styles-constants';
import { PdfNativeTableColumns, PdfPrecastTableColumns } from '../../constants/pdf-table-columns-connstants';
// eslint-disable-next-line max-len
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss'],
  providers: [PdfReportUtils, DatePipe],
})
export class ViewReportComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  projectId = '';
  soilType = '';
  previewData: REPORTPREVIEW;
  reportId: string;
  address: IADDRESS;
  signature: ISIGNATURE;
  sigArray: ISIGNATURE[];
  title = '';
  projectName = '';
  hasBillingInfo = false;
  PONumber: string;
  selectedIndex = 0;
  savedChangeRecleted = false;
  logo: string;
  forPdfTableDta: any[] = [];
  figureImages: any[] = [];
  imageCount = 0;
  // sampleCount = 0;
  figureCount = 0;
  // sampleCompletedCount = 0;
  statusId: any;
  statusLabel: any;
  reportOwner: any;
  exportShow:any;
  projectData: any;
  allWorksheetApiRes: any;
  headerData: any;
  rowData: any;
  public worksheetRecords!: WorksheetResponse;
  noteText: string | undefined = '';
  note: INotes | undefined;
  geothermField: boolean;
  docTableData: any[] = [];
  paragraphs = '';
  formattedDate: string;
  private destroyed$ = new Subject<boolean>();
  currentStatus: string;
  showTabs = false;
  figureCompletedArray: (boolean | null)[] = [];
  splitFigureData: any[] = [];
  sampleIDs: any = {
  };

  figureCompletedCount = 0;
  constructor(
    private adminService: AllAdminService,
    public activatedRoute: ActivatedRoute,
    private formService: FormService,
    private reportService: ReportService,
    private http: HttpClient,
    private datePipe: DatePipe,
    public userService: UserService,
    public dialog: MatDialog,
    private precastService: PrecastService,
    private sharedService: SharedService,
    private exporttableService: ExportTableService,
    private pdfExportUtil: PdfReportUtils,
    private wordExportUtil: WordReportUtils,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.sharedService.geothermField$.subscribe((value) => {
      this.exportShow = value;
    });

    const type = this.activatedRoute.snapshot.paramMap.get('type');
    const e = this.activatedRoute.snapshot.queryParamMap.get('edit');
    if (e && e === 'true') {
      this.selectedIndex = 1;
    }
    this.soilType = type !== null ? type : '';
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.projectId = id !== null ? id : '';

    this.getPreviewData();

    this.getAdminDetails();
    this.formService?.updateData(null);
    this.getGraphData();
    this.convertImageToDataURL('/assets/images/p-logo.jpg')
      .then((dataURL) => {
        this.logo = dataURL;
      })
      .catch(() => {});
  }

  getAddress(data: ADMINGENERAL[]): IADDRESS {
    const address: IADDRESS = {
      address: '',
      city: '',
      state: '',
      ph: '',
      fax: '',
      email: '',
      street1: '',
      street2: '',
      weburl: '',
    };
    if (data) {
      data.forEach((elem: ADMINGENERAL) => {
        if (elem.key === 'companyaddress') {
          address.address = elem.value;
        } else if (elem.key === 'city') {
          address.city = elem.value;
        } else if (elem.key === 'state') {
          address.state = elem.value;
        } else if (elem.key === 'contactnumber') {
          address.ph = elem.value;
        } else if (elem.key === 'email') {
          address.email = elem.value;
        } else if (elem.key === 'street1') {
          address.street1 = elem.value;
        } else if (elem.key === 'street2') {
          address.street2 = elem.value;
        } else if (elem.key === 'weburl') {
          address.weburl = elem.value;
        } else if (elem.key === 'fax') {
          address.fax = elem.value;
        }
      });
    }
    return address;
  }

  public getPreviewData(): void {
    const reportData$ = this.reportService?.fetchReportData(this.projectId, this.soilType);
    const signatures$ = this.reportService?.getSignature();
    forkJoin({
      reportData: reportData$,
      signatures: signatures$,
    })?.subscribe({
      next: ({ reportData, signatures }) => {
        this.reportId = reportData.id;
        this.previewData = reportData;
        this.reportOwner = this.previewData.assignedTo;
        this.previewData.reportDate = this.getActualDate(this.previewData.reportDate);
        this.sigArray = signatures;

        const index = this.sigArray.findIndex((x: ISIGNATURE) => x.id === reportData.signatureTypeId);
        if (index > -1) {
          this.signature = this.sigArray[index];
        }
        this.forPdfTableDta = [];
        this.previewData.thermalData.forEach((element) => {
          if (this.soilType === SoilType.Native) {
            this.forPdfTableDta.push({
              sampleId: element.sampleId,
              depth: element.depth,
              effort: element.effort,
              description: element.description,
              wet: element.wet,
              dry: element.dry,
              moistureContent: element.moistureContent,
              density: element.density,
            });
          } else if (this.soilType === SoilType.Precast) {
            this.forPdfTableDta.push({
              sampleId: element.sampleId,
              collectionDate: this.datePipe.transform(element.collectionDate, 'MM/dd/yyyy'),
              matrialType: element.matrialType,
              moistureContent: element.moistureContent,
              density: element.density,
              wet: element.wet,
              dry: element.dry,
            });
          }
        });
        this.reportService.mlGraphData = reportData.mlData;
        this.getProjectInfo(this.projectId, this.soilType);
      },
    });
  }

  openAssignToDialog() {
    this.dialog
      .open(AssignToLabtechComponent, {
        data: {
          projectId: this.projectId,
          type: this.soilType,
        },
        disableClose: true,
        width: '40%',
      })
      .afterClosed()
      .subscribe((userInfo) => {
        if (userInfo) {
          this.reportOwner = `${userInfo.firstName} ${userInfo.lastName}`;
        }
        this.initialExcelFile();
      });
  }

  openSetStatusDialog() {
    this.dialog
      .open(SetStatusComponent, {
        data: {
          projectId: this.projectId,
        },
        disableClose: true,
        width: '40%',
      })
      .afterClosed()
      .subscribe((status: any) => {
        if (status.statusId) {
          this.statusId = status?.statusId;
          this.fetchAllStatus();
        }
      });
  }

  private fetchAllStatus(): void {
    this.precastService?.fetchProjectsLookup()?.subscribe({
      next: (lookupResponse: IDropdown[]) => {
        this.statusLabel = lookupResponse.find((status) => (status.id === this.statusId ? status : ''))?.label;
      },
    });
  }

  getProjectInfo(projectId: string, soilType: string): void {
    this.formService?.fetchFormData(projectId, soilType)?.subscribe({
      next: (data) => {
        this.worksheetRecords = data;
        this.reportService.projectInformation = data;
        this.hasBillingInfo = data.company.hasBillingInfo;
        this.projectName = data?.project?.projectName;
        this.PONumber = data.project.projectNumber;
        this.statusId = data.project.projectStatusTypeId;
        const dueDate = data.project.dueDate ? data.project.dueDate : '';
        this.geothermField = data.project.geothermField;
        this.showTabs = true;
        const date = new Date(dueDate);
        this.title = `${data?.company?.name}-${data?.project?.projectName}-${
          soilType === SoilType.Precast ? 'PC' : 'NS'
        }`;
        if (data?.project?.projectNumber) {
          this.title = `${this.title}-${data?.project?.projectNumber}`;
        }
        const formatedDueDate = dueDate
          ? `-${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}${date
            .getFullYear()
            .toString()}`
          : '';
        this.title = `${this.title}${formatedDueDate}`;
        this.fetchAllStatus();
        if (this.geothermField) {
          this.initialExcelFile();
        }
      },
      error: () => {},
    });
  }

  getAdminDetails() {
    this.adminService?.getAdminDetails()?.subscribe((data: any) => {
      this.address = this.getAddress(data);
    });
  }

  getUpdatedPriviewData(): void {
    this.reportService?.fetchReportData(this.projectId, this.soilType)?.subscribe({
      next: (reportData) => {
        this.previewData = reportData;
        this.previewData.reportDate = this.getActualDate(this.previewData.reportDate);
        const index = this.sigArray.findIndex((x: ISIGNATURE) => x.id === reportData.signatureTypeId);
        if (index > -1) {
          this.signature = this.sigArray[index];
        }
      },
    });
  }

  changeTab(tabGroup: MatTabGroup, event: boolean) {
    if (event) {
      this.getUpdatedPriviewData();
    }
    tabGroup.selectedIndex = 0;
  }

  onTabChanged(event: MatTabChangeEvent) {
    if (event.index === 0) {
      if (this.savedChangeRecleted) {
        this.getUpdatedPriviewData();
        this.savedChangeRecleted = false;
      }
    }
  }

  getSavedNotify(event: boolean): void {
    this.savedChangeRecleted = event;
  }

  getActualDate(date: string | Date): Date {
    const utcDate = new Date(date);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - localTimeZoneOffset * 60000);
    return localDate;
  }

  // PDF Document creating function
  public generatePDF(): void {
    this.pdfExportUtil.commonForPdfData(
      this.previewData,
      this.soilType,
      this.title,
      this.signature,
      this.logo,
      this.address,
      this.figureImages,
      this.imageCount,
      this.forPdfTableDta,
    );
    this.pdfExportUtil.exportPDF();
  }

  convertImageToDataURL(imagePath: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http
        .get(imagePath, {
          responseType: 'blob',
        })
        .subscribe(
          (blob: Blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
            reader.onerror = (error) => {
              reject(error);
            };
            reader.readAsDataURL(blob);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  getImageCount(event: number): void {
    this.imageCount = event;
  }

  /**
 * Retrieves graph data from the server based on the project ID and soil type.
 */
  getGraphData(): void {
    this.reportService.getGraphData(this.projectId, this.soilType)?.subscribe({
      next: (data) => {
        this.figureCount = 0;
        // Split the figure logic and count the number of figures
        const countValue = this.sharedService.splitFigureLogic(data);
        this.figureCount = Object.keys(countValue).length;
        Object.assign(this.sampleIDs, this.sharedService.splitFigureLogic(data));
        const figLength = Object.keys(this.sampleIDs).length;
        // Create an array of null values with the length of the figure array
        this.figureCompletedArray = Array(figLength).fill(null);
        // Store the split figure data
        this.splitFigureData = data;
        this.getFigureCompletedCount();
        this.cdr.detectChanges();
        // data.forEach((graphDataArray: any[]) => {
        //   let completed = true;
        //   graphDataArray.forEach((each) => {
        //     if (!each.graphStatus) {
        //       completed = false;
        //     }
        //   });
        // this.sampleCount += 1;
        // if (completed) {
        //   this.sampleCompletedCount += 1;
        // }
        // });
      },
      error: () => {},
    });
  }

  /**
 * Calculates the completion status of figures based on the `graphStatus` of each item.
 * Updates the `figureCompletedArray` to reflect whether each figure is complete.
 * Calls `figCompletedCount()` to update the total count of completed figures.
 */

  getFigureCompletedCount(): void {
    let splicestart = 0;
    Object.keys(this.sampleIDs).forEach((key: any, i: number) => {
      const dataToLoad = this.splitFigureData.slice(splicestart, splicestart + this.sampleIDs[key]);
      let figCompleted = true;
      dataToLoad.forEach((items: any) => {
        if (!items[0].graphStatus) {
          figCompleted = false;
        }
      });
      this.figureCompletedArray[i] = figCompleted;
      splicestart += this.sampleIDs[key];
    });
    this.figCompletedCount();
  }

  /**
 * Calculates the count of completed figures.
 * @returns The count of completed figures.
 */

  figCompletedCount(): number {
    let completed = 0;
    this.figureCompletedArray.forEach((isCompleted) => {
      if (isCompleted === true) {
        completed += 1;
      }
    });

    this.figureCompletedCount = completed;
    return completed;
  }

  initialExcelFile() {
    this.sharedService.fetchAllTabsData();
    this.sharedService.getNativeSoilWs(this.projectId);
    this.sharedService.fetchWSNotes(this.projectId);
    this.sharedService.headerData$.subscribe((data) => {
      this.headerData = data;
    });
    this.sharedService.rowData$.subscribe((data) => {
      this.rowData = data;
    });
    this.sharedService.notes$.subscribe((data) => {
      this.note = data;
    });
    this.sharedService.notesText$.subscribe((data) => {
      this.noteText = data;
    });
  }

  // Excel File creating function

  exportcsv() {
    /**
     * Fetches project statuses and checks for "Management Review."
     * If found, prepares a payload and calls updateStatus() to change the status.
     */
    this.precastService?.fetchProjectsLookup().subscribe({
      next: (lookupResponse: IDropdown[]) => {
        const managementReviewStatus = lookupResponse.find((status) => status.label === 'Management Review');
        if (managementReviewStatus) {
          this.currentStatus = managementReviewStatus.label;
          this.statusId = managementReviewStatus.id;
          const payload: ISetStatus = {
            projectId: this.projectId,
            statusId: this.statusId,
            note: null,
          };
          // Call the method to update status
          this.updateStatus(payload);
        }
      },
    });
  }

  /**
   * Updates the project's status using the provided payload.
   * On successful update, calls fetchAllStatus() and then exportData().

   */
  updateStatus(payload: ISetStatus) {
    this.precastService?.setStatus(payload).subscribe({
      next: () => {
        this.fetchAllStatus();
        this.exportData();
      },
    });
  }

  /**
   * Exports the project's data. Formats necessary details and uses
   * exporttableService to open the data in a new tab.
   */

  exportData() {
    // Logic to export the data
    const firstName = this.note?.updatedBy?.firstName || '';
    const lastName = this.note?.updatedBy?.lastName || '';
    const createdAt = this.note?.createdAt;
    const formattedTime = createdAt ? this.sharedService.transform(createdAt) : '';
    const notesUpdatedBy = `${firstName} ${lastName}: ${formattedTime}`;
    this.exporttableService.openInNewTab(
      this.headerData,
      this.rowData,
      this.worksheetRecords,
      notesUpdatedBy,
      this.noteText,
      this.soilType,
    );
  }

  // Word Document creating function
  public generateWord() {
    this.wordExportUtil.commonForWordData(
      this.previewData,
      this.soilType,
      this.title,
      this.signature,
      this.logo,
      this.address,
      this.figureImages,
      this.imageCount,
      this.forPdfTableDta,
    );
    this.wordExportUtil.generateWord();
  }

  openWorksheet() {
    const url = `${AzureAdB2C.redirectUri}worksheet?soilType=${this.soilType}&&projectId=${this.projectId}`;
    window.open(url, '_blank');
  }

  openViewSubmissionForm() {
    const url = `${AzureAdB2C.redirectUri}review/form/${this.soilType}/${this.projectId}`;
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
