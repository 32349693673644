import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import Quill from 'quill';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-richtext-editor',
  templateUrl: './richtext-editor.component.html',
  styleUrls: ['./richtext-editor.component.scss'],
})
export class RichtextEditorComponent implements AfterViewInit, OnDestroy {
  editor: any;
  initialContent = '';
  contentChangeSubject = new Subject<string>();
  @Output() contentChanged = new EventEmitter<string>();
  @Input() set content(value: string) {
    if (this.initialContent !== value) {
      this.initialContent = value;
      if (this.editor && this.editor.root.innerHTML !== value) {
        const range = this.editor.getSelection();
        this.editor.root.innerHTML = value;
        if (range) {
          this.editor.setSelection(range.index, range.length);
        }
      }
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.contentChangeSubject.pipe(debounceTime(300)).subscribe((content) => {
      this.contentChanged.emit(content);
    });
  }

  ngAfterViewInit() {
    const container = this.elementRef.nativeElement.querySelector('#editor-container');

    if (container) {
      this.editor = new Quill(container, {
        theme: 'snow',
        readOnly: false,
        modules: {
          toolbar: [
            [{
              header: [1, 2, 3, 4, 5, 6, false],
            }],
            ['bold', 'italic', 'underline', 'strike'],
            [{
              list: 'ordered',
            }, {
              list: 'bullet',
            }],
            ['link', 'image'],
            [{
              color: [],
            }, {
              background: [],
            }],
            [{
              align: [],
            }],
          ],
        },
      });
      this.editor.root.innerHTML = this.initialContent;
      this.editor.on('text-change', () => {
        const content = this.editor.root.innerHTML;
        if (content === '<p><br></p>' || content.trim() === '<p><br></p>') {
          this.contentChangeSubject.next(''); // Send empty string instead
        } else {
          this.contentChangeSubject.next(content);
        }
      });
    } else {
      console.error('Editor container not found');
    }
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.disable();
    }
    this.contentChangeSubject.unsubscribe();
  }
}
