<div class="precast-edit-wrapper" id="precast-edit-wrapper">
  <h2 mat-dialog-title class="mat-h2">
    Pre Cast Data
    <button mat-icon-button class="close-button" (click)="closeDialog('')">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <form [formGroup]="editWorksheetForm">
      <div class="col-12">
        <h1 class="mat-h1 list-title">
          <img *ngIf="worksheetRow.status" alt="tag" class="ml-8 mr-8 tick" src="../../../../assets/icons/Check.svg" />
          <span class="breakWord">{{ worksheetRow.inHouseId }}</span> Sample Data
        </h1>
      </div>
      <mat-error *ngIf="titleError">In House ID is duplicate. Please enter unique In House ID.</mat-error>
      <mat-error *ngIf="!validWeight">Dry weight cannot be higher than Wet weight, please check and re-enter</mat-error>
      <mat-error *ngIf="!validTR">Dry TR cannot be lower than Wet TR, please check test results</mat-error>
      <div class="n-tab">
        <mat-tab-group animationDuration="0" [(selectedIndex)]="selectedIndex">
          <mat-tab label="MEASUREMENTS">
            <div class="tab-height">
              <div class="row">
                <div class="col-md-1 pt-12">
                  <img alt="tag" class="icon-alignment" src="../../../../assets/icons/Tag.svg" />
                </div>
                <div class="col-md-9 pt-16 mat-subtitle-1 list-title">In-House ID<span class="required">*</span></div>
                <div class="col-md-2 pt-8">
                  <div class="inputdisabled" >
                    <mat-form-field appearance="outline" class="edw-form-field ">
                      <input matInput   
                            maxlength="5" 
                            (keyup)="updateModalTitle()" 
                            formControlName="inHouseId" required 
                            />
                    </mat-form-field>
                  </div>
                  
                </div>
                <mat-divider></mat-divider>
                <div class="col-md-1 pt-12">
                  <img alt="measure" class="icon-alignment" src="../../../../assets/icons/scale.svg" />
                </div>
                <div class="col-md-9 pt-16 mat-subtitle-1 list-title">
                  Tare (gm)
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip="Max. 5 digits allowed, without decimal. e.g. XXXXX"
                    src="../../../../assets/icons/Alert-grey.svg" />
                </div>
                <div class="col-md-2 pt-8">
                  <mat-form-field appearance="outline" class="edw-form-field">
                    <input
                      matInput
                      type="text"
                      placeholder="e.g. XXXXX"
                      appRemoveLeadingZero
                      required
                      appOnlyInteger
                      appNumberInput
                      formControlName="tare" />
                  </mat-form-field>
                </div>
                <div *ngIf="worksheetRow.sampleType === 'FTB' || worksheetRow.sampleType === 'GROUT/SLURRY' " class="row">
                  <mat-divider></mat-divider>
                  <div class="col-md-1 pt-12">
                    <!-- <img alt="measure" class="icon-alignment" src="../../../../assets/icons/scale.svg" /> -->
                  </div>
                  <div class="col-md-9 pt-16 mat-subtitle-1 list-title">
                    Mold (gm)
                    <img
                      alt="info Icon"
                      class="star-mar-top"
                      #tooltip="matTooltip"
                      matTooltip="Max. 5 digits allowed, without decimal. e.g. XXXXX"
                      src="../../../../assets/icons/Alert-grey.svg" />
                  </div>
                  <div class="col-md-2 pt-8">
                    <mat-form-field appearance="outline" class="edw-form-field">
                      <input
                        matInput
                        type="text"
                        placeholder="e.g. XXXXX"
                        appRemoveLeadingZero
                        required
                        appOnlyInteger
                        appNumberInput
                        formControlName="mould" />
                    </mat-form-field>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <div class="col-md-1 pt-12">
                  <img alt="tag" class="icon-alignment" src="../../../../assets/icons/Measure.svg" />
                </div>
                <div class="col-md-9 pt-16 mat-subtitle-1 list-title">
                  Sample Diameter (mm)
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                    src="../../../../assets/icons/Alert-grey.svg" />
                </div>
                <div class="col-md-2 pt-8">
                  <mat-form-field appearance="outline" class="edw-form-field">
                    <input
                      matInput
                      type="text"
                      placeholder="e.g. XXX.X"
                      formControlName="sampleDiameter"
                      required
                      maxlength="5"
                      appRemoveLeadingZero
                      appOneDigitDecimaNumber
                      appDecimalOnly />
                  </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="col-md-1 pt-12">
                  <img alt="measure" class="icon-alignment" src="../../../../assets/icons/Measure.svg" />
                </div>
                <div class="col-md-9 pt-16 mat-subtitle-1 list-title">
                  Sample Height (mm)
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                    src="../../../../assets/icons/Alert-grey.svg" />
                </div>
                <div class="col-md-2 pt-8">
                  <mat-form-field appearance="outline" class="edw-form-field">
                    <input
                      matInput
                      type="text"
                      placeholder="e.g. XXX.X"
                      appRemoveLeadingZero
                      formControlName="sampleHeight"
                      required
                      maxlength="5"
                      appOneDigitDecimaNumber
                      appDecimalOnly />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="WET TESTS">
            <div class="tab-height">
              <div class="row" formArrayName="multiPointTests">
                <ng-container *ngFor="let sample of getmultiPointTests.controls; let i = index">
                  <ng-container [formGroupName]="i">
                    <div class="col-md-1 pt-12">
                      <img alt="measure" class="icon-alignment" src="../../../../assets/icons/scale.svg" />
                    </div>
                    <div class="col-md-7 pt-16 mat-subtitle-1 list-title">
                      Wet + Tare (gm)
                      <img
                        alt="info Icon"
                        class="star-mar-top"
                        #tooltip="matTooltip"
                        matTooltip="Max. 5 digits allowed, without decimal. e.g. XXXXX"
                        src="../../../../assets/icons/Alert-grey.svg" />
                    </div>
                    <div class="col-md-2 pt-16" align="right">
                      <button mat-icon-button (click)="removeItem(i)" *ngIf="getmultiPointTests.length > 1 && i !== 0">
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                    </div>
                    <div class="col-md-2 pt-8">
                      <mat-form-field appearance="outline" class="edw-form-field">
                        <input
                          matInput
                          type="text"
                          placeholder="e.g. XXXXX"
                          appRemoveLeadingZero
                          formControlName="wetPlusTare"
                          required
                          appOnlyInteger />
                      </mat-form-field>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-11">
                      <mat-divider></mat-divider>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-1 pt-12">
                      <img alt="scale" class="icon-alignment" src="../../../../assets/icons/connecting.svg" />
                    </div>
                    <div class="col-md-8 pt-16 mat-subtitle-1 list-title">
                      Wet TR (gm)
                      <img
                        alt="info Icon"
                        class="star-mar-top"
                        #tooltip="matTooltip"
                        matTooltip="Max. 5 digits allowed, without decimal. e.g. XXXXX"
                        src="../../../../assets/icons/Alert-grey.svg" />
                    </div>
                    <div class="col-md-2 pt-8">
                      <mat-form-field appearance="outline" class="edw-form-field">
                        <input
                          matInput
                          type="text"
                          placeholder="e.g. XXXXX"
                          appRemoveLeadingZero
                          formControlName="wetTr"
                          required
                          appOnlyInteger />
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-divider></mat-divider>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="col-md-9"></div>
                <div class="col-md-3 pt-8" align="right">
                  <a mat-button href="javascript:void(0)" (click)="addSample()" *ngIf="getmultiPointTests.length < 4">
                    + Add Wet TR Test
                  </a>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="DRY TESTS">
            <div class="tab-height">
              <div class="row">
                <div class="col-md-1 pt-12">
                  <img alt="measure" class="icon-alignment" src="../../../../assets/icons/scale.svg" />
                </div>
                <div class="col-md-9 pt-16 mat-subtitle-1 list-title">
                  Dry + Tare (gm)
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip="Max. 5 digits allowed, without decimal. e.g. XXXXX"
                    src="../../../../assets/icons/Alert-grey.svg" />
                </div>
                <div class="col-md-2 pt-8">
                  <mat-form-field appearance="outline" class="edw-form-field">
                    <input
                      matInput
                      type="text"
                      placeholder="e.g. XXXXX"
                      appRemoveLeadingZero
                      formControlName="dryPlusTare"
                      required
                      appOnlyInteger />
                  </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="col-md-1 pt-12">
                  <img alt="scale" class="icon-alignment" src="../../../../assets/icons/connecting.svg" />
                </div>
                <div class="col-md-9 pt-16 mat-subtitle-1 list-title">
                  Dry TR (gm)
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip="Max. 5 digits allowed, without decimal. e.g. XXXXX"
                    src="../../../../assets/icons/Alert-grey.svg" />
                </div>
                <div class="col-md-2 pt-8">
                  <mat-form-field appearance="outline" class="edw-form-field">
                    <input
                      matInput
                      type="text"
                      placeholder="e.g. XXXXX"
                      appRemoveLeadingZero
                      formControlName="dryTr"
                      required
                      appOnlyInteger />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </form>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <button
      mat-raised-button
      [disabled]="currentIndex === 0 || (editWorksheetForm.dirty && !editWorksheetForm.valid)"
      (click)="navigateRecords('PREVIOUS')">
      <mat-icon>navigate_before</mat-icon>
      PREV
    </button>
    <span class="spacer"></span>
    <button
      mat-raised-button
      color="primary"
      (click)="saveWorksheetData(true)"
      [disabled]="titleError || !editWorksheetForm.valid"
      *ngIf="this.userService.roleCheck(['Manager', 'Admin', 'Labtech'])">
      SAVE
    </button>
    <button
      mat-raised-button
      class="c-next"
      [disabled]="editWorksheetForm.dirty && !editWorksheetForm.valid"
      *ngIf="currentIndex !== worksheets.length - 1"
      (click)="navigateRecords('NEXT')">
      NEXT<mat-icon>navigate_next</mat-icon>
    </button>
    <button
      mat-raised-button
      [disabled]="editWorksheetForm.dirty && !editWorksheetForm.valid"
      *ngIf="currentIndex === worksheets.length - 1"
      (click)="navigateRecords('DONE')">
      DONE
    </button>
  </mat-dialog-actions>
</div>
