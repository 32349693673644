import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { AdminHeader } from 'src/app/shared/models/admin-header.model';
import { AllAdminService } from 'src/app/shared/services/all-admin.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { Router } from '@angular/router';
import { NativeSoilService } from '../worksheet/services/native-soil/native-soil.service';
import { INotes } from '../models/inotes.modal';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  asRecivedWorksheetsData: AdminHeader[] = [];
  moistureWorksheetsData: AdminHeader[] = [];
  nativeSoilWorksheetsData: AdminHeader[] = [];
  proctorWorksheetsData: AdminHeader[] = [];
  allWorksheetApiRes:any;
  noteText: string | undefined = '';
  note: INotes | undefined;
  private destroyed$ = new Subject<boolean>();
  private headerDataSource = new BehaviorSubject<any>(null);
  private rowDataSource = new BehaviorSubject<any>(null);
  private notesData = new BehaviorSubject<any>(null);
  private notesTextData = new BehaviorSubject<any>(null);
  private geothermFieldSubject = new BehaviorSubject<boolean>(false);
  headerData$ = this.headerDataSource.asObservable();
  rowData$ = this.rowDataSource.asObservable();
  notes$ = this.notesData.asObservable();
  notesText$ = this.notesTextData.asObservable();
  geothermField$ = this.geothermFieldSubject.asObservable();
  soilType:string;
  constructor(
    public userService: UserService,
    private adminService: AllAdminService,
    private nativeSoilService: NativeSoilService,
    private precastService: PrecastService,
    public router: Router,
  ) {}

  /* native soil row data api call started */
  getNativeSoilWs(projectId:any) {
    this.nativeSoilService.getNativeSoilWorkSheet(projectId).subscribe({
      next: (response: any) => {
        const allWorksheetApiRes = JSON.parse(JSON.stringify(response));
        const updateDepth = (worksheet: any) => {
          if (worksheet.depth == null && worksheet.endDepth === null && worksheet.stockPile === false) {
            worksheet.depth = '';
          } else if (worksheet.depth == null && worksheet.endDepth === null && worksheet.stockPile === true) {
            worksheet.depth = 'StockPile';
          } else if (worksheet.depth !== undefined && worksheet.endDepth !== undefined) {
            worksheet.depth = `${worksheet.depth}-${worksheet.endDepth}`;
          }
        };

        const customEffort = (worksheet: any) => {
          if (worksheet.worksheetType === 'Tube') {
            worksheet.effort = 'Tube';
          }
        };

        const customAssignTo = (worksheet: any) => {
          if (worksheet.assignedTo) {
            const updatedByFirstName = worksheet.assignedTo?.firstName;
            const updatedByLastName = worksheet.assignedTo?.lastName;
            worksheet.assignedTo = `${updatedByFirstName} ${updatedByLastName}`;
          }
        };

        const customDate = (worksheet: any) => {
          if (worksheet.collectionDate) {
            const formattedCastDate = worksheet.collectionDate.split('T')[0];
            worksheet.collectionDate = `${formattedCastDate} `;
          }
        };
        // excel export data  ended
        // received worksheets
        (allWorksheetApiRes.asRecivedWorksheets || []).forEach((worksheet: any) => {
          updateDepth(worksheet);
          customDate(worksheet);
          customAssignTo(worksheet);
        });

        // moisture worksheets
        (allWorksheetApiRes.moistureWorksheets || []).forEach((worksheet: any) => {
          updateDepth(worksheet);
          customAssignTo(worksheet);
        });

        // proctor worksheets
        (allWorksheetApiRes.proctorWorksheets || []).forEach((worksheet: any) => {
          updateDepth(worksheet);
          customAssignTo(worksheet);
        });

        // native soil worksheets
        (allWorksheetApiRes.nativeSoilWorksheets || []).forEach((worksheet: any) => {
          updateDepth(worksheet);
          customEffort(worksheet);
          customAssignTo(worksheet);
        });
        this.allWorksheetApiRes = allWorksheetApiRes;
        this.rowDataSource.next(this.allWorksheetApiRes);
      },
      error: (err) => {
        console.error('Error fetching worksheets:', err);
      },
    });
  }
  /* native soil row data api call ended */

  /* native soil all header data api call started */
  fetchWorksheetTabData(): Observable<any[]> {
    return this.adminService.getNativeCompaction().pipe(
      map((response) => {
        const sortedData = response.sort((a: AdminHeader, b: AdminHeader) => a.sortOrder - b.sortOrder);
        return sortedData;
      }),
      catchError((error) => {
        console.error('Error fetching worksheet tab data:', error);
        return of([]);
      }),
    );
  }

  fetchMoistureWorksheetsTabData(): Observable<any[]> {
    return this.adminService.getNativeMoisture().pipe(
      map((response) => {
        const sortedData = response.sort((a: AdminHeader, b: AdminHeader) => a.sortOrder - b.sortOrder);
        return sortedData;
      }),
      catchError((error) => {
        console.error('Error fetching moisture worksheets data:', error);
        return of([]);
      }),
    );
  }

  fetchReceivedSheetTabData(): Observable<any[]> {
    return this.adminService.getNativeReceived().pipe(
      map((response) => {
        const sortedData = response.sort((a: AdminHeader, b: AdminHeader) => a.sortOrder - b.sortOrder);
        return sortedData;
      }),
      catchError((error) => {
        console.error('Error fetching received sheet data:', error);
        return of([]);
      }),
    );
  }

  fetchProctorWorksheetsTabData(): Observable<any[]> {
    return this.adminService.getNativeProctor().pipe(
      map((response) => {
        const sortedData = response.sort((a: AdminHeader, b: AdminHeader) => a.sortOrder - b.sortOrder);
        return sortedData;
      }),
      catchError((error) => {
        console.error('Error fetching proctor worksheets data:', error);
        return of([]);
      }),
    );
  }

  fetchAllTabsData(): void {
    forkJoin({
      asRecivedWorksheets: this.fetchReceivedSheetTabData(),
      moistureWorksheets: this.fetchMoistureWorksheetsTabData(),
      nativeSoilWorksheets: this.fetchWorksheetTabData(),
      proctorWorksheets: this.fetchProctorWorksheetsTabData(),
    }).subscribe(
      (combinedResults) => {
        this.asRecivedWorksheetsData = combinedResults.asRecivedWorksheets;
        this.moistureWorksheetsData = combinedResults.moistureWorksheets;
        this.nativeSoilWorksheetsData = combinedResults.nativeSoilWorksheets;
        this.proctorWorksheetsData = combinedResults.proctorWorksheets;
        this.headerDataSource.next(
          {
            asRecivedWorksheets: this.asRecivedWorksheetsData,
            moistureWorksheets: this.moistureWorksheetsData,
            nativeSoilWorksheets: this.nativeSoilWorksheetsData,
            proctorWorksheets: this.proctorWorksheetsData,
          },
        );
      },
      (error) => {
        console.error('Error fetching all tabs data:', error);
      },
    );
  }
  /* native soil all header data api call ended */

  /* Note get call started */
  fetchWSNotes(projectID: string): void {
    this.precastService
      .getNotesbyId(projectID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: INotes[]) => {
          this.note = response[0] ? response[0] : undefined;
          this.notesData.next(this.note);
          if (this.note?.text && this.note?.text.length > 105) {
            this.noteText = `${this.note?.text.substring(0, 85)}...`;
            this.notesTextData.next(this.noteText);
          } else {
            this.noteText = this.note?.text.toString();
            this.notesTextData.next(this.noteText);
          }
        },
      });
  }
  /* Note get call ended */

  /* Utc to local date and time  convert for api updation */

  transform(utcDateString: string, dateOrTime?: string): string {
    const utcDate = new Date(utcDateString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - localTimeZoneOffset * 60000);
    if (dateOrTime === 'date') {
      return localDate.toLocaleDateString();
    }
    if (dateOrTime === 'time') {
      return localDate.toLocaleTimeString();
    }
    return localDate.toLocaleString();
  }

  // geothermField Test flag enable or disable
  setGeothermField(value: boolean): void {
    this.geothermFieldSubject.next(value);
  }

  // collect the soiltype
  soilTypes(soiltype:any) {
    this.soilType = soiltype;
  }

  // report screen split figurelogic
  splitFigureLogic(graphData: any) {
    const sampleIDs: any = {
    };
    if (this.soilType === SoilType.Native) {
      graphData.forEach((datarow: any) => {
        const smplID = `${datarow[0].sampleId}`;
        if (Object.keys(sampleIDs).findIndex((id) => id === smplID) < 0) {
          Object.assign(sampleIDs, {
            [smplID]: 1,
          });
        } else {
          Object.assign(sampleIDs, {
            [smplID]: sampleIDs[smplID] + 1,
          });
        }
      });
    } else {
      graphData.forEach((datarow: any) => {
        const smplID = `${datarow[0].sampleType}`;
        if (Object.keys(sampleIDs).findIndex((id) => id === smplID) < 0) {
          Object.assign(sampleIDs, {
            [smplID]: 1,
          });
        } else {
          Object.assign(sampleIDs, {
            [smplID]: sampleIDs[smplID] + 1,
          });
        }
      });
    }
    const keys = Object.keys(sampleIDs);
    keys.forEach((key) => {
      if (sampleIDs[key] > 6) {
        const split = Math.ceil(sampleIDs[key] / 6);
        for (let i = 0; i < split; i++) {
          sampleIDs[`${key}-${i}`] = 6;
          if (i === split - 1) {
            sampleIDs[`${key}-${i}`] = sampleIDs[key] - 6 * i;
          }
        }
      }
      if (sampleIDs[key] > 6) {
        delete sampleIDs[key];
      }
    });
    return sampleIDs;
  }

  // view submission redirec to same page after edit submission
  routeValue(routeTo:string) {
    const [path, queryString] = routeTo.split('?');
    const queryParams = queryString ? this.parseQueryString(queryString) : {
    };
    this.router.navigate([path], {
      queryParams,
    }).then(() => {
      // Reload the page after navigation
      window.location.reload();
    });
  }

  private parseQueryString(queryString: string): { [key: string]: string } {
    const params = new URLSearchParams(queryString);
    const result: { [key: string]: string } = {
    };
    params.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }

  ngOnDestroy(): void { // avoid memory leakage please don't remove the line
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
