<div *ngIf="previewData">
  <div class="main-body">
    <p [innerHTML]="previewData.mainBody"></p>
    <p><strong>Test Results: (Average of Cylinders)</strong></p>
  </div>
  <div class="rpreview-sample-wrapper">
    <table>
      <tr>
        <th rowspan="2">
          <span>Sample ID</span>
        </th>
        <th rowspan="2">
          <span>Date Cast </span>
        </th>
        <th rowspan="2">
          <span>Material Type </span>
        </th>
        <th rowspan="2">
          <span>Moisture Content</span>
          <span>(%)</span>
        </th>
        <th rowspan="2">
          <span>Dry Density</span>
          <span>(lb/ft3)</span>
        </th>
        <th colspan="2">
          <span>Thermal Resistivity</span>
          <span>(°C-cm/W)</span>
        </th>
      </tr>
      <tr class="b">
        <th class="c">
          <span>Wet</span>
        </th>
        <th class="c">
          <span>Dry</span>
        </th>
      </tr>
      <tr class="p" *ngFor="let td of previewData?.thermalData">
        <td>{{ td.sampleId }}</td>
        <td>{{ td.collectionDate | date : 'mediumDate' }}</td>
        <td>{{ td.matrialType }}</td>
        <td>{{ td.moistureContent }}</td>
        <td>{{ td.density }}</td>
        <td>{{ td.wet }}</td>
        <td>{{ td.dry }}</td>
      </tr>
    </table>
  </div>
</div>
